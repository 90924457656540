import React from 'react';
import Layout from '../../components/Layout';
import SubPageWrapper from '../../components/SubPageWrapper';
import Seo from '../../components/seo';
import Title from '../../components/Title';
import * as Styles from '../../styles/pages/contact/_complete.module.scss';
import { Link } from 'gatsby';
import BackButton from '../../components/back-button';
import Inner from '../../components/Inner';
import { SectionTitle } from '../../components/SectionTitle';
import { css } from '@emotion/react';

export default function ContactCompletePage() {
  const breadcrumb = [
    { label: 'お問い合わせ', url: '/contact' },
    { label: '入力内容の確認', url: '/contact/confirmation' },
    { label: 'お問い合わせ完了', url: '/contact/thanks' },
  ];

  return (
    <wrapRootElement>
      <Layout breadcrumb={breadcrumb}>
        <Seo
          url="/contact/thanks"
          title="お問い合わせ・サンクスページ｜Uniforce（ユニフォース）株式会社"
          description="決算開示・IPO支援業務、Uniforce IPO準備クラウド・決算開示管理クラウドやその他ご相談などはこちらからお問い合わせください。"
          image="https://uniforce.co.jp/og-image.jpg"
          breadcrumbs={breadcrumb}
          meta={[
            {
              name: 'robots',
              content: 'noindex, nofollow',
            },
            {
              name: 'googlebot',
              content: 'noindex, nofollow',
            },
          ]}
        />
        <SubPageWrapper>
          <div className={Styles.contact__complete}>
            <Inner>
              <SectionTitle
                enText="Thanks"
                titleText="お問い合わせ完了"
                css={css`
                  position: relative;
                  padding-top: 140px;
                  z-index: 2;
                  max-width: 1158px;
                  margin: auto;

                  @media (max-width: 1020px) {
                    margin-left: -25px;
                  }
                `}
              />
              <p className={Styles.contact__complete__message}>
                この度はお問い合わせメールをお送りいただきありがとうございます。
                <br />
                お送りいただきました内容を確認の上、
                <br className="sp-only" />
                担当者より折り返しご連絡させていただきます。
                <br />
                <br />
                万が一メールが届かない場合は、
                <br />
                ご入力いただいたメールアドレスに間違いがある可能性や場合や
                <br className="pc-only" />
                迷惑メールフォルダに振り分けられてしまった可能性がございます。
                <br />
                <br />
                迷惑メールフォルダ及びメールアドレスをご確認の上、
                <br className="pc-only" />
                再度お問い合わせくださいますようよろしくお願いします。
              </p>

              <div className={Styles.contact__complete__btn}>
                <Link to="/contact/confirmation">
                  <BackButton to="/contact/confirmation" />
                </Link>
              </div>
            </Inner>
          </div>
        </SubPageWrapper>
      </Layout>
    </wrapRootElement>
  );
}
